import { useState } from 'react';
import { Autoplay, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { PressSliderThumb } from './PressSliderThumb';
import { Schema } from './PressSlider.schema';

export function PressSlider({ cms }) {
  const { section, slides } = cms;
  const { bgColor, fullWidth, textColor } = { ...section };

  const [mainSwiper, setMainSwiper] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const maxWidthContainerClass = fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  const hasLogos = slides?.some(({ image }) => image);

  return (
    <div
      data-comp={PressSlider.displayName}
      className="md:px-contained py-contained"
      style={{
        background: bgColor,
        backgroundImage: bgColor,
        color: textColor,
      }}
    >
      {slides?.length > 0 && (
        <div className={`mx-auto pt-4 ${maxWidthContainerClass}`}>
          <Swiper
            autoplay={{
              delay: 8000,
              disableOnInteraction: false,
            }}
            centeredSlides
            className={`${!hasLogos ? '[&_.swiper-wrapper]:items-center' : ''}`}
            grabCursor
            modules={[Autoplay, Thumbs]}
            onActiveIndexChange={(swiper) => {
              if (!thumbsSwiper) return;
              setActiveIndex(swiper.activeIndex);
              thumbsSwiper.slideTo(swiper.activeIndex);
            }}
            onSwiper={setMainSwiper}
            slidesPerView={1}
            thumbs={{ swiper: thumbsSwiper }}
          >
            {slides.map(({ quote }, index) => {
              return (
                <SwiperSlide className="w-full py-2" key={index}>
                  <h2 className="text-alt-h2 mx-auto max-w-[50rem] px-4 text-center">
                    &quot;{quote}&quot;
                  </h2>
                </SwiperSlide>
              );
            })}
          </Swiper>

          {/* desktop */}

          {hasLogos && (
            <>
              <ul
                className="mx-auto mt-8 hidden gap-8 md:grid"
                style={{
                  gridTemplateColumns: `repeat(${slides.length}, 1fr)`,
                  width: `calc(1/6*100%*${slides.length})`,
                }}
              >
                {slides.map(({ alt, image }, index) => {
                  const isActive = index === activeIndex;
                  return (
                    <li key={index} className="flex justify-center">
                      <PressSliderThumb
                        alt={alt}
                        image={image}
                        isActive={isActive}
                        onClick={() => {
                          if (!mainSwiper) return;
                          mainSwiper.slideTo(index);
                        }}
                      />
                    </li>
                  );
                })}
              </ul>

              {/* mobile */}
              <div className="mt-4 md:hidden">
                <Swiper
                  centeredSlides
                  className="max-md:cursor-grab"
                  modules={[Thumbs]}
                  onSwiper={setThumbsSwiper}
                  slidesPerView={2.25}
                  onActiveIndexChange={(swiper) => {
                    if (!mainSwiper) return;
                    mainSwiper.slideTo(swiper.activeIndex);
                  }}
                >
                  {slides.map(({ alt, image }, index) => {
                    const isActive = index === activeIndex;
                    return (
                      <SwiperSlide
                        className="flex justify-center px-4"
                        key={index}
                      >
                        <PressSliderThumb
                          alt={alt}
                          image={image}
                          isActive={isActive}
                          onClick={() => {
                            if (!mainSwiper) return;
                            mainSwiper.slideTo(index);
                          }}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

PressSlider.displayName = 'PressSlider';
PressSlider.Schema = Schema;
